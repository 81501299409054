body {
    margin: 0;
    height: 100%;
}
html {
    height: 100%;
}
#root {
    height: 100%;
}

#loading {
    position: fixed;
}